import {useState} from 'react';
import {StyleSheet, FlatList} from 'react-native';
import {Card, SearchBar} from 'react-native-elements';

import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import HeaderSearchbar from "../components/HeaderSearchbar";
import React from 'react';
import {COMPANY_DATA} from "../state/test_state";

export default function SearchScreen({ navigation }: RootTabScreenProps<'Search'>) {
  const [companies, setCompanies] = useState(COMPANY_DATA);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderSearchbar onQueryResult={(result) => {setCompanies(result)}} />
      )
    });
  }, [navigation]);



  const renderCompany = ({ item }) => {
    return <Company
      cik={item.cik}
      name={item.names[0].replace(/\s\s+/g, ' ')}
      names={item.names.slice(1)}
      tickers={item.tickers}
    />
  };

  return (<FlatList
    styles={styles.companies}
    data={companies}
    renderItem={renderCompany}
    keyExtractor={(company) => company.cik}
  />);
}

const Company = ({ cik, name, tickers, names}) => {
  if (tickers.length > 0) {
    console.log(name);
    console.log(tickers);
  }
return (<View >
    {/*<Text>{cik}</Text>*/}
    <Card style={styles.company} key={cik} onPress={(e) => console.log(e)}>
      <Card.Title>{name}</Card.Title>
      <Card.Divider/>
      {tickers.length > 0 && <View><Text style={styles.companyTickers}>{"Tickers: " + tickers.join(", ")}</Text></View> }
      {names.map((c, i) => {
        // console.log(c);
        return (
          <View key={i}><Text style={styles.companyAddlNames}>{c}</Text></View>
        );
      })}
    </Card>
    {/*<Text>{name}</Text>*/}
    {/*<Text>{tickers}</Text>*/}
  </View>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  companies: {
    flex: 1
  },
  company: {
    width: '100%',
    padding: 16,
    // height: 4
    backgroundColor: '#333',
    marginVertical: 4,
    marginHorizontal: 4
  },
  companyAddlNames: {
    backgroundColor: '#fff',
    color: '#000'
  },
  companyTickers: {
    backgroundColor: '#fff',
    color: '#000'
  }
});
