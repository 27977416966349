import {SearchBar} from "react-native-elements";
import * as React from "react";
import {useState} from "react";

export default function HeaderSearchbar(props) {

  const [query, setQuery] = useState("");
  // const [companies, setCompanies] = useState([]);
  const [controller, setController] = useState(new AbortController());

  const onQueryChange = function(newQuery) {
    // console.log(newQuery);
    if (newQuery !== query) {
      controller.abort();
      let newController = new AbortController();
      const signal = newController.signal;
      fetch('https://data.cashandcashequivalents.com/v1/cik-query?q=' + encodeURIComponent(newQuery), {
        signal // abort signal
      })
        .then(response => response.json())
        .then(data => {
          // console.log(JSON.stringify(data.companies));
          props.onQueryResult(data.companies);
          // if (JSON.stringify(companies) !== JSON.stringify(data.companies)) {
          //
          // }
          // setCompanies(data.companies);
        });
      setQuery(newQuery);
      setController(newController);
    }
  }

  return (
    <SearchBar
      platform="default"
      placeholder="Ticker or Name"
      onChangeText={onQueryChange}
      value={query}
    />
  );
}